<template>
  <div class="article-box" v-loading="loading">
    <div class="page-nav clearfix">
      <ul>
          <li>
              <router-link class="nav-item" to="/institution/communication">
                  交流讨论&nbsp;&nbsp;/
              </router-link>
          </li>
          <li class = "nav-item nav-item-last">
              &nbsp;&nbsp;查看详情
          </li>
      </ul>
  </div>
    <!-- 标题 -->
    <div class="pag-title clearfix">
        <div class="page-title-name pull-left">交流讨论</div>
        <div class="page-title-button pull-right" @click="addApply">添加回复</div>
    </div>
    <div class="no-data-img"  v-if="noData"></div>
    <div v-for="(item, index) in msgDetail" :key="index" class="msg-list-item">
      <div class="clearfix">
        <div class="pull-left msg-name">{{item.nickName}}</div>
        <div class="pull-right msg-timer">{{item.createDate}}</div>
      </div>
      <div class="msc-text">{{item.content}}</div>
    </div>
    <el-dialog title="回复" :visible.sync="isShoRreply">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入回复内容"
          v-model="replyText">
        </el-input>
        <div style="margin: 20px" class="clearfix">
            <div class="button-item button-search pull-right" @click="sureHandler()"  style="margin-left: 20px">确定</div>
            <div class="button-item button-cancle pull-right" @click="cancelHandler()">取消</div>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { CListDiscussion, DiscussionAdd } from "@/http/api/InitiationApi";
export default {
  data() {
    return {
      loading: false,
      uid: '',
      noData: false,
      msgDetail:[],
      replyText: '',
      isShoRreply: false,
      replyLoading: true
    };
  },
  computed: {},
  created() {
    this.uid = this.$route.query.id
    this.getDetail(this.uid) 
  },
  methods: {
    getDetail(id){
      this.loading = true
      CListDiscussion({id: id}).then(resp => {
        this.loading = false
        if (resp.code == '000') {
          this.msgDetail = resp.result || []
          this.noData = this.msgDetail.length ? false : true 
        } else {
          this.$message({
            message: resp.msg || '获取数据有误',
            type: 'error'
          });
        }
      })
    },
    addApply () {
      this.replyText = '';
      this.isShoRreply = true;
    },
    cancelHandler () {
      this.replyText = '';
      this.isShoRreply = false;
    },
    sureHandler () {
      if (this.uid && this.replyText) {
        this.loading = false
        DiscussionAdd({receiveUserId: this.uid, content: this.replyText}).then(resp => {
          this.loading = false
          if (resp.code == '000') {
            this.cancelHandler()
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.getDetail(this.uid)
          } else {
              this.$message({
                message: resp.msg || '操作失败',
                type: 'error'
              });
          }
        })
      } else {
            this.$message({
              message: '数据有误',
              type: 'error'
            });
      }
    }
  },
};
</script>
<style lang='less' scoped>
.msg-list {
  width: 100%;
}
.msg-list-item {
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(10, 42, 97, 0.2);
  border-radius: 2px;
}
.msg-name {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
}
.msg-timer {
  font-size: 14px;
  font-weight: 400;
  color: #969799;
  line-height: 30px;
}
.msc-text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
</style>